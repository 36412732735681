@keyframes move {
    100% {
      transform: translate3d(0, 0, -1000px);
    }
  }
  
  .bubble-wrap {
    margin: 0 auto;
    width: 30%;
    perspective: 7000px;
    margin-left: -100px;
    transform-style: preserve-3d;
    transform-origin: center center;
  }

  .bubble {
    position: absolute;
    background: black;
    border-radius: 50%;
    opacity: 0.1;
    z-index: -10;
    animation: move 6s infinite;
    // display: none;
    
  }
  
  @for $i from 1 through 100 {
    .bubble:nth-child(#{$i}){
       $size: random(180)+px;
       height: $size;
       width: $size;
       animation-delay: -$i * .2s;
       transform: translate3d( (random(1000) * 1px),  (random(1000) * 1px), (random(2000) * 1px));
       background: hsl( random(71) , 80%, 50%);
       background:rgb(167, 131, 35);
    }
  }

  /* ............................................ MOBILE VIEW ---- max-width: 500px */
  @media only screen and (max-width: 500px) {
    
    .bubble-wrap {
      width: 100%;
      perspective: 1000px;
      margin-left: -180px;
      transform-style: preserve-3d;
      transform-origin: center center;
    }
  
    .bubble {
      opacity: 0.2;
      z-index: -1000;
      animation: move 16s infinite;
    }

    @for $i from 1 through 100 {
      .bubble:nth-child(#{$i}){
         $size: random(100)+px;
         height: $size;
         width: $size;
         animation-delay: -$i * .2s;
         transform: translate3d( (random(1000) * 1px),  (random(1000) * 1px), (random(2000) * 1px));
         background: hsl( random(71) , 80%, 50%);
         background:rgb(167, 131, 35);
      }
    }
  }