/* Start Here */

/* global setting for linkable hover color  */
$highlightColor: #F9C43A;
$highlightColor: rgb(167, 131, 35);
$backgroundColor: rgb(0, 0, 0);
// $backgroundColor: none;

@font-face {
  font-family: Atma;
  src: url(./fonts/atma/Atma-Regular.ttf);
}

@font-face {
  font-family: Atma;
  src: url(./fonts/atma/Atma-Bold.ttf);
  font-weight: bold;
}

* {
  box-sizing: border-box;
}

body {
  background-color: black;
}

.App {
  width: 100%;
  /* height: 100%; */
  // background-color: black;
}

.highlight-color {
  color: $highlightColor;
}

.background-color {
  background-color: $backgroundColor;
}

#player-controls i:hover {
  color: rgb(167, 131, 35);
}