// global styles across full web site regardless of media query
nav {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
    height: 70px;
    // background-color: blue;
    // overflow: hidden;
}

.topnav {
    // margin: 0 auto;
    // background-color: pink;
    height: 70px;
}
  
  /* Style the links inside the navigation bar */
  .topnav a {
    display: block;
    float: left;
    font-family: Atma;
    color: #f2f2f2;
    text-decoration: none;
    // text-transform: uppercase;
    // text-align: center;
    // background-color: red;
  }

  /* Hide the link that should open and close the topnav on small screens */
  .topnav .icon {
    display: none;
  }

  /* The animation code */
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }   

  .fade-in {
  animation-name: fadeIn;
  animation-duration: 1.3s;
  }
//.............................................max-width: 811px
@media screen and (max-width: 811px) {
    .topnav a {
        padding: 14px 28px;
        font-size: 18px;
      }
    // hide all links;
    .topnav a {display: none;}
    .topnav button.icon {  // show mobile icon
      float: left;
      display: block;
      cursor: pointer;
      margin-left: 20px;
      margin-top: 15px;
      background: none;
      color: white;
    }

    /* The "responsive" class added to topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
    .topnav.responsive {
      position: relative; 
      margin-top: 50px;
      // background-color: black;
      // opacity: 0.9;
    }

    .topnav.responsive button.icon {
      position: fixed;
      left: 0px;
      top: 0px;
    }

    /* The animation code */
@keyframes enter-menu {
  0%   {margin-left: -125px; opacity: 0;}
  80% { opacity: 1;}
  100% {margin-left: 10px;}
}

    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
      color: white;
      animation-name: enter-menu;
      animation-duration: 0.3s;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      background-color: black;
      opacity: 0.95;
      width: 135px;
      border-bottom: 1px solid #272727;
      border-right: 1px solid #272727;
    }

    .topnav.responsive a:first-child {
      display: none;
    }

  }
  //.............................................min-width: 812px
  @media screen and (min-width: 812px) {
    .topnav {
      width: 800px;
  }
    
  .topnav a {
      padding: 20px 20px;
      font-size: 17px;
  }
  }

  //.............................................min-width: 950px
  @media only screen and (min-width: 950px) {
    .topnav {
      width: 920px;
  }
    
  .topnav a {
      padding: 20px 30px;
      font-size: 17px;
  }
  }

    //.............................................min-width: 1,200px
    @media only screen and (min-width: 1200px) {
        .topnav {
            width: 1150px;
        }
          
        .topnav a {
            padding: 20px 40px;
            font-size: 21px;
        }
      }

      //.............................................min-width: 1,500px
    @media only screen and (min-width: 1480px) {
        .topnav {
            width: 1480px;
        }
          
        .topnav a {
            padding: 20px 70px;
        }
      }