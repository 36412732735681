.record-spins {
    animation: record-spin infinite 2s linear;
    /* height: 40vmin; */
    pointer-events: none;
  }
  
  @keyframes record-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  #record-player-container {
    height: auto;
    width: 500px;
    background-color: green;
    position: relative;
  }

  #record-player,
    #record-playhead,
    #record-vinyl {
      position: fixed;
    }

    /* ............................................ max width 500px */
  @media only screen and (max-width: 700px) {
    #record-player {
      z-index: 0;
    }

    #record-player,
    #record-playhead  {
      width: 250px;
      height: 203px;
      bottom: -10px;
      left: 0px;
    }
  
    #record-vinyl {
      width: 162px;
      height: 162px;
      left: 28px;
      bottom: 13px;
    }
  }

        /* ............................................ min-width: 501px */
  @media only screen and (min-width: 701px) {

    #record-player,
    #record-playhead  {
      width: 250px;
      height: 203px;
      bottom: 100px;
      left: 50px;
    }
  
    #record-vinyl {
      width: 162px;
      height: 162px;
      left: 76px;
      bottom: 121px;
    }
  }

    /* ............................................ min-width: 993px */
  @media only screen and (min-width: 993px) {

    #record-player {
      z-index: -1;
    }

    #record-player,
    #record-playhead  {
      width: 350px;
      height: 284px;
      bottom: 100px;
      left: 100px;
    }
  
    #record-vinyl {
      width: 228px;
      height: 228px;
      left: 137px;
      bottom: 128px;
    }

  }

  /* ............................................ min width 1245px */
  @media only screen and (min-width: 1245px) {
    
    #record-player {
      z-index: -1;
    }

    #record-player,
    #record-playhead  {
      height: 325px;
      width: 400px;
      bottom: 100px;
      left: 100px;
    }
  
    #record-vinyl {
      height: 263px;
      width: 263px;
      left: 142px;
      bottom: 132px;
    }
    
  }