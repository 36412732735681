$myfont: Atma;


  #hero {
    color: #dddddd;
    width: 100%;
    height: auto;
    overflow: hidden;
    // background-color: white;
  }

  #record-container {
    // background-color: red;
  }

    

  #hero-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    margin-left: -180px;
  }

  #show-container {
      text-align: center;
      padding: 16px 46px;
      // height: 650px;
      // background-color: blue;
  }

  #show-container h1 {
    font-family: $myfont;
    font-size: 28px;
  }

  #show-container h2 {
    font-family: $myfont;
    font-size: 25px;
  }

  #show-container h3 {
    font-family: $myfont;
    font-size: 24px;
  }

  #show-container h4 {
    font-family: $myfont;
    font-size: 22px;
  }

  #show-container p {
    font-family: $myfont;
    font-size: 20px;
    line-height: 24px; 
  }

  .mobile-footer-background,
  .mobile-header-text {
    display: none;
  }
  

  /* ............................................ MOBILE VIEW ---- max-width: 500px */
  @media only screen and (max-width: 700px) {

    #hero {
      padding-bottom: 200px;
    }

    .mobile-footer-background {
      display: block;
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 65px;
      background-color: black;
    }

    #record-container h1 {
      font-family: $myfont;
      font-size: 22px;
    }

    .mobile-header-text {
      display: block;
      position: absolute;
      top: 4px;
      left: 70px;
    }

    #hero-backdrop {
      top: 0;
      left: 0;
      z-index: -1;
      margin-left: -180px;
      opacity: 0.33;
    }

    #hero-backdrop img {
      // height: 300px;
    }

    #show-container {
      width: 100%;
      padding: 16px 15px;
      margin-top: 50px;
      height: auto;
    }

    #show-container h1 {
      font-size: 24px;
    }
  
    #show-container h2 {
      font-size: 23px;
    }
  
    #show-container h3 {
      font-size: 22px;
    }
  
    #show-container h4 {
      font-size: 20px;
    }
  
    #show-container p {
      font-size: 18px;
      line-height: 23px; 
    }

  }

  /* ............................................ max width 992px */
  @media only screen and (min-width: 701px) {

  #show-container {
    width: 100%;
    margin-top: 80px;
  }
}

// /* ............................................ min width 992px */
// @media only screen and (max-width: 700px) {
//   #show-container {
//     float: left;
//     width: 100%;
//     margin-top: 70px;
//   }
// }

/* ............................................ min width 992px */
@media only screen and (min-width: 701px) {
  #show-container {
    width: 50%;
    float: right;
  }
}


  /* ............................................ min width 992px */
  @media only screen and (min-width: 992px) {

    #record-container,
    #show-container {
      // float: left;
      // margin-top: 70px;
    }

    #record-container {
      position: relative;
      height: 100%;
      padding: 50px 0;
      // background-color: red;
    }

    #show-container {
      height: auto;
      width: 50%;
      // background-color: blue;
    }
  }

  /* ............................................ min width 1068px */
  @media only screen and (max-width: 1087px) {
    #hero-backdrop {
      position: fixed;
      top: -50px;
      left: 0;
      z-index: -1;
      height: 1270px;
      width: auto;
      margin-left: -180px;
    }

  }

  /* ............................................ min width 1245px */
  @media only screen and (min-width: 1088px) {
    
    #hero-backdrop {
      position: fixed;
      top: 0;
      left: 30px;
      z-index: -1;
      height: 1270px;
      width: auto;
      margin-left: 0px;
    }

    #show-container {
      width: 50%;
      margin-top: 0px;
    }
    
  }

  /* ............................................ min width 1800px */
  @media only screen and (min-width: 1800px) {

    
  }




  