
/* ----------------------------------------------
 * Generated by Animista on 2019-8-21 2:39:58
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bck-center {
    0% {
      -webkit-transform: translateZ(600px);
              transform: translateZ(600px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bck-center {
    0% {
      -webkit-transform: translateZ(600px);
              transform: translateZ(600px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }

  .slide-in-bck-center {
    -webkit-animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  